export const registerPlugins = (plugins) => {
  return {
    install(app) {
      plugins.forEach(plugin => {
        app.use(plugin)
      })
    }
  }
}

// 存储localStorage
export const setStore = (name, content) => {
  if (!name) return
  localStorage.setItem(name, JSON.stringify(content))
}

// 获取localStorage
export const getStore = name => {
  if (!name) return
  return JSON.parse(localStorage.getItem(name))
}

export function removeSpace(value) {
  return value.replace(/\s+/g, '')
}

export function formValidate(val, type) {
  const phoneReg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  if (val === '') {
    return false
  } else {
    // 非空验证
    if (type === 'require') {
      return !!removeSpace(val)
    }
    if (type === 'phone') {
      return phoneReg.test(val)
    }
    if (type === 'email') {
      return emailReg.test(val)
    }
  }
}

export function isEmptyObject(obj ) {
  if(obj == null) {
    return true
  }
  var name;
  for ( name in obj ) {
      return false;
  }
  return true;
}

// 获取url参数
export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

// 数组去重
export function dedupe(array) {
  return Array.from(new Set(array))
}

// 判断pc还是手机端
export function isMobile() {
  console.log('isMobile')
  var flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  console.log(flag)
  return flag != null
}

const getZero = (num) => {
  // 单数前面加0
  if (parseInt(num) < 10) {
    num = '0' + num;
  }
  return num;
}

//获取当前日期 yyyy-mm-dd
export function getCurrentTime() {
  var date = new Date();//当前时间
  var year = date.getFullYear() //返回指定日期的年份
  var month = date.getMonth() + 1;//月
  var day = date.getDate();//日
  var hour = getZero(date.getHours());//时
  var minute = getZero(date.getMinutes());//分
  // var second = repair(date.getSeconds());//秒

  //当前时间
  var curTime = year + "-" + month + "-" + day + " " + hour + ":" + minute
  return curTime;
}

//获取当前日期间隔第n天 yyyy-mm-dd
export function getCountTime(day=1) {
  var date = new Date((new Date().getTime() - day*24*60*60*1000))
  var year = date.getFullYear() //返回指定日期的年份
  var month = date.getMonth() + 1;//月
  var day = date.getDate();//日
  var hour = getZero(date.getHours());//时
  var minute = getZero(date.getMinutes());//分
  // var second = repair(date.getSeconds());//秒

  //当前时间
  var curTime = year + "-" + month + "-" + day
  return curTime;
}

//时间戳转换为时间 Y-M-D
export function getTimeYMD(time)
{
  // 比如需要这样的格式 yyyy-MM-dd hh:mm:ss
  var date = new Date(time);
  var year = date.getFullYear();
  var month = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return year + "-" + month + "-" + day;
}

//时间戳转换为时间 Y-M-D h:m:s
export function getTimeYMDHMS(time)
{
  // 比如需要这样的格式 yyyy-MM-dd hh:mm:ss
  var date = new Date(time);
  var year = date.getFullYear();
  var month = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  
  var hour = getZero(date.getHours());//时
  var minute = getZero(date.getMinutes());//分
  var second = getZero(date.getSeconds());//秒

  return year + "-" + month + "-" + day  + " " + hour + ":" + minute + ":" + second;
}

// 时间变时间戳
export function getTimeStamp(DateTime) {
  var target_dt = DateTime.replace(/-/g, '/')
  var date = new Date(target_dt).getTime();
  return date
}

// 判断现在时间是否属于这个时间段
export function isDuringDate(beginDateStr, endDateStr) {
  var curDate = new Date()
  var curTime = getTimeYMD(curDate)
  var beginDate = new Date(curTime + ' ' + beginDateStr)
  var endDate = new Date(curTime + ' ' + endDateStr)
  if (curDate >= beginDate && curDate <= endDate) {
    return true;
  }
  return false;
}

// 标准时间转日期(年-月-日)
export function dateToHMD(date) {
  var yy = date.getFullYear() // 年
  var mm = date.getMonth() + 1 // 月
  var dd = date.getDate() // 日
  var clock = yy + '-'
  if (mm < 10) clock += '0'
  clock += mm + '-'
  if (dd < 10) clock += '0'
  clock += dd
  return clock
}

// 深拷贝
export function deepCopy(data) {
  return JSON.parse(JSON.stringify(data))
}

export const ModalHelper = ((bodyCls) => {
  let scrollTop
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop
      document.body.classList.add(bodyCls)
      document.body.style.top = -scrollTop + 'px'
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls)
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop
    }
  }
})('dialog-open')
