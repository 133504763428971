/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/home.js
 * 主页
 */
export default [{
  path: '/home',
  name: 'home',
  meta: {
    index: 1,
    title: "主页"
  },
  component: () => import('@/views/home/index.vue')
},
{
  path: '/chat',
  name: 'chat',
  meta: {
    index: 2,
    title: "聊天"
  },
  component: () => import('@/views/chat/index.vue')
},
{
  path: '/history',
  name: 'history',
  meta: {
    index: 2,
    title: "历史"
  },
  component: () => import('@/views/history/index.vue')
}]
