/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/home.js
 * 我的
 */
export default [{
  path: '/mine',
  name: 'mine',
  meta: {
    index: 1,
    title: "我的"
  },
  component: () => import('@/views/mine/index.vue')
}]
